import React, { useEffect, useLayoutEffect, useState } from "react";
import { useHistory } from "react-router";
import { useForm } from "react-hook-form";

import * as httpService from "./services/httpService";
import * as datasetService from "./services/datasetService";

import CustomSelect from "../../../../SubsiteWide/components/custom-select/custom-select.js";
import Form from "./components/form/form.component";
import { useUpdateEffect } from "./hooks/useUpdateEffect";

const CreatePost = () => {
  const preloadedValues = {
    senderTypeId: 1,
    // contactType: "email",
    fileName: "",
    fileType: "",
    videoSrc: "",
    title: "",
    text: "",
    name: "",
    lastname: "",
    email: "",
    terms: false,
    age: "",
    zipcode: "",
    organization: "",
    personTitle: "",
    placeOfEducation: "",
    holdClass: "",
    phone: "",
    countryCode: "",
    FilmCompetition: false,
    video: "",
    photo: "",
    category: ""
  };

  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    setValue,
    getValues,
    trigger,
    reset,
  } = useForm({
    mode: "all",
    defaultValues: preloadedValues,
  });

  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFailed, setIsSubmitFailed] = useState(false);
  const [isCaptchaValidated, setIsCaptchaValidated] = useState(false);
  const [errorCode, setErrorCode] = useState("");

  const [formStep, setFormStep] = useState(1);
  const [croppedImage, setCroppedImage] = useState("");
  const [videoSrc, setVideoSrc] = useState("");
  const watchVideo = watch("video");
  const watchPhoto = watch("photo");
  const [loading, setLoading] = useState(false);
  const [isFirstFormStepValid, setIsFirstFormStepValid] = useState(false);

  const captchaMetaData = datasetService.getCaptchaMetaData();
  const dictionary = datasetService.getDictionary();
  const siteId = datasetService.getSiteId();
  const history = useHistory();

  const { FormStepOnePath, FormStepTwoPath, FormStepThreePath } =
  dictionary.FormSteps;

  useEffect(() => {
    setPostTypeId();
  }, [watchPhoto, watchVideo]);

  useUpdateEffect(() => {
    if (formStep === 1) {
      const customSelects = document.querySelectorAll(".custom-select");
      customSelects.forEach((select) => {
        CustomSelect.init(select);
      });
    }
  }, []);

  const hasVideo = () => {
    const videoValue = getValues("video");
    return typeof videoValue !== "undefined" && videoValue;
  };

  const hasPhoto = () => {
    const photoValue = getValues("photo");
    return typeof photoValue !== "undefined" && photoValue.length > 0;
  };

  const loadStepOne = () => {
    setFormStep(1);
    history.push(FormStepOnePath);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const loadStepTwo = () => {
    setFormStep(2);
    history.push(FormStepTwoPath);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const loadStepThree = () => {
    setFormStep(3);
    history.push(FormStepThreePath);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  const setPostTypeId = () => {
    const video = hasVideo();
    const photo = hasPhoto();
    if (video) setValue("postTypeId", 1);
    if (photo) setValue("postTypeId", 2);
    if (!video && !photo) setValue("postTypeId", 3);
  };

  const createFormData = (data) => {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    formData.append("siteId", siteId);

    return formData;
  };

  const onSubmit = (data) => {
    if (!isCaptchaValidated) {
      $("#captchaModal").show();
      $("#captchaModal").find($(".captcha-modal__input").focus());
      $("body").addClass("captcha-open");
      $(
        "#content :focusable, .content-action-links :focusable, .footer-container :focusable, .skip"
      )
        .not(".captcha-modal__content :focusable")
        .addClass("unfocus")
        .attr("tabindex", -1)
        .attr("disabled", true);
    } else {
      setLoading(true);
      httpService
        .createPost(createFormData(data))
        .then((res) => {
          if (res.ok) {
            setLoading(false);
            setIsSubmitSuccess(true);
            loadStepThree();
          }
          if (!res.ok) {
            setLoading(false);
            setErrorCode(res.status);
            setIsSubmitFailed(true);
            loadStepThree();
          }
        })
        .catch((error) => {
          setLoading(false);
          setIsSubmitFailed(true);
          loadStepThree();
        });
    }
  };

  return (
    <Form
      dictionary={dictionary}
      getValues={getValues}
      errorCode={errorCode}
      setFormStep={setFormStep}
      setIsSubmitFailed={setIsSubmitFailed}
      setIsSubmitSuccess={setIsSubmitSuccess}
      formStep={formStep}
      register={register}
      unregister={unregister}
      errors={errors}
      setValue={setValue}
      croppedImage={croppedImage}
      setCroppedImage={setCroppedImage}
      videoSrc={videoSrc}
      setVideoSrc={setVideoSrc}
      watch={watch}
      setPostTypeId={setPostTypeId}
      isValid={isValid}
      trigger={trigger}
      hasVideo={hasVideo}
      isCaptchaValidated={isCaptchaValidated}
      captchaMetaData={captchaMetaData}
      setIsCaptchaValidated={setIsCaptchaValidated}
      loading={loading}
      isSubmitFailed={isSubmitFailed}
      isSubmitSuccess={isSubmitSuccess}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      loadStepOne={loadStepOne}
      loadStepTwo={loadStepTwo}
      loadStepThree={loadStepThree}
      resetFormData={reset}
      preloadedValues={preloadedValues}
      isFirstFormStepValid={isFirstFormStepValid}
      setIsFirstFormStepValid={setIsFirstFormStepValid}
    />
  );
};

export default CreatePost;
