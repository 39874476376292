export default function Loading() {
  return (
    <div className="loading">
      <div className="container">
        <span className="object"></span>
      </div>
      <div className="container">
        <span className="object"></span>
      </div>
      <div className="container">
        <span className="object"></span>
      </div>
      <div className="container">
        <span className="object"></span>
      </div>
      <div className="container">
        <span className="object"></span>
      </div>
      <div className="container">
        <span className="object"></span>
      </div>
      <div className="container">
        <span className="object"></span>
      </div>
      <div className="container">
        <span className="object"></span>
      </div>
      <div className="container">
        <span className="object"></span>
      </div>
      <div className="container">
        <span className="object"></span>
      </div>
      <div className="container">
        <span className="object"></span>
      </div>
      <div className="container">
        <span className="object"></span>
      </div>
      <div className="container">
        <span className="object"></span>
      </div>
      <div className="container">
        <span className="object"></span>
      </div>
      <div className="container">
        <span className="object"></span>
      </div>
      <div className="container">
        <span className="object"></span>
      </div>
      <div className="container">
        <span className="object"></span>
      </div>
      <div className="container">
        <span className="object"></span>
      </div>
      <div className="container">
        <span className="object"></span>
      </div>
      <div className="container">
        <span className="object"></span>
      </div>
    </div>
  );
}
