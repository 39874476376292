import React, { useState, Fragment, useEffect } from "react";

import { useUpdateEffect } from "../../hooks/useUpdateEffect";
import * as datasetService from "../../services/datasetService";

import ModalImageUpload from "../modal-image-upload/modal-image-upload.component";

const CreatePostContent = (props) => {
  const {
    register,
    unregister,
    errors,
    dictionary,
    setFormValue,
    videoSrc,
    setVideoSrc,
    croppedImage,
    setCroppedImage,
    watch,
    setPostTypeId,
  } = props;

  const categories = datasetService.getCategories();
  const videoFileMaxSize = 2147483648; // bytes (2gb)
  const imageFileMaxSize = 7340032; //bytes (7mb)
  const videoAccept =
    "video/flv, video/asf, video/qt, video/mov, video/mpg, video/mpeg, video/avi, video/wmv, video/mp4, video/m4v, video/3gp, video/vob, video/f4v, video/mkv, video/mxf, video/mts, video/ts, video/rm, video/arf, video/webm, video/lxf, video/x-m4v, video/quicktime";
  const imageAccept = "image/png, image/jpg, image/jpeg";

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const [videoSrcError, setVideoSrcError] = useState("");
  const [imageSrcError, setImageSrcError] = useState("");

  useUpdateEffect(() => {
    setFormValue("photo", croppedImage);
  }, [croppedImage]);

  const handleImageReset = () => {
    setCroppedImage("");
    setImgSrc("");
    setFormValue("fileName", "");
    setFormValue("fileType", "");
  };

  const handleVideoReset = () => {
    setVideoSrc("");
    setFormValue("video", "");
    setFormValue("fileName", "");
    setFormValue("fileType", "");
    unregister("FilmCompetition");
  };

  const handleImageChange = (file) => {
    if (!verifyImageFile(file)) return;
    setImgSrc(URL.createObjectURL(file));
    setFormValue("fileName", file.name);
    setFormValue("fileType", file.type);
  };

  const handleVideoChange = (event) => {
    const file = event.target.files[0];
    if (!verifyVideoFile(file)) return;
    setVideoSrc(file);
    setFormValue("fileName", file.name);
    setFormValue("fileType", file.type);
    setFormValue("video", file);
    setPostTypeId();
  };

  const toggleModal = (event) => {
    const html = document.querySelector(".subsite-wide");
    if (typeof event !== "undefined") {
      event.preventDefault();
      setIsModalOpen(!isModalOpen);
    } else {
      setIsModalOpen(!isModalOpen);
    }
    !isModalOpen
      ? html.classList.add("prevent-scroll")
      : html.classList.remove("prevent-scroll");
  };

  const isFileSizeValid = (file, fileMaxSize) => {
    return file.size < fileMaxSize;
  };

  const isFileTypeValid = (file) => {
    return videoAccept.includes(file.type);
  };

  const verifyVideoFile = (file) => {
    if (!isFileSizeValid(file, videoFileMaxSize)) {
      setVideoSrcError(dictionary.Errors.VideoSize);
      return false;
    }

    if (!isFileTypeValid(file)) {
      setVideoSrcError(dictionary.Errors.VideoType);
      return false;
    }

    setVideoSrcError("");
    return true;
  };

  const isImageTypeValid = (file) => {
    return imageAccept.includes(file.type);
  };

  const verifyImageFile = (file) => {
    if (!isFileSizeValid(file, imageFileMaxSize)) {
      setImageSrcError(dictionary.Errors.ImageSize);
      return false;
    }

    if (!isImageTypeValid(file)) {
      setImageSrcError(dictionary.Errors.ImageType);
      return false;
    }

    setImageSrcError("");
    return true;
  };

  return (
    <div className="forum-create-post__debate">
      <div className="forum-create-post__input-group">
        <div className="forum-input-row row">
          <div className="forum-input-col col-md-6">
            <label
              htmlFor="CategorySelectOptions"
              className="visually-hidden"
              tabIndex={-1}
            >
              Tema
            </label>
            <select
              id="category"
              className={`custom-select ${
                errors.category ? "input--error" : ""
              }`}
              data-idname="CategorySelectOptions"
              {...register("category", {
                required: {
                  value: true,
                  message: dictionary.Errors.Required.Category,
                },
              })}
              aria-label="category"
            >
              <option value="" defaultValue hidden>
                {dictionary.Placeholders.Category}
              </option>
              {categories.map((category) => (
                <option key={category.ID} value={category.ID}>
                  {category.Name}
                </option>
              ))}
              ;
            </select>
            {errors.category && (
              <div className="input-error-message">
                {errors.category.message}
              </div>
            )}
          </div>
        </div>

        <div className="forum-input-row row">
          <div className="forum-input-col col-md-12">
            <input
              className={errors.title ? "input--error" : ""}
              {...register("title", {
                required: {
                  value: true,
                  message: dictionary.Errors.Required.DebateTitle,
                },
              })}
              type="text"
              aria-label={dictionary.Placeholders.DebateTitle}
              placeholder={dictionary.Placeholders.DebateTitle}
              tabIndex={isModalOpen ? "-1" : "0"}
              onKeyDown={(event) => {
                if (event.key === "enter") return;
              }}
            />
            {errors.title && (
              <div className="input-error-message">{errors.title.message}</div>
            )}
          </div>
        </div>

        <div className="forum-input-row row">
          <div className="forum-input-col col-md-12">
            <textarea
              className={errors.text ? "input--error" : ""}
              {...register("text", {
                required: {
                  value: true,
                  message: dictionary.Errors.Required.DebateContent,
                },
              })}
              cols="30"
              rows="10"
              aria-label={dictionary.Placeholders.DebateContent}
              placeholder={dictionary.Placeholders.DebateContent}
              tabIndex={isModalOpen ? "-1" : "0"}
            />
            {errors.text && (
              <div className="input-error-message">{errors.text.message}</div>
            )}
          </div>
        </div>

        <div class="forum-input-row row">
          <div class="forum-input-col col-md-12">
              <p>{dictionary.Labels.RequiredFieldText}</p>
          </div>
        </div>

        <div className="forum-create-post__file-upload">
          {videoSrc && (
            <div className="cropped-image">
              <div
                className="clear-img-preview"
                onClick={handleVideoReset}
                tabIndex={isModalOpen ? "-1" : "0"}
                aria-label={dictionary.Placeholders.DeleteVideo}
                role="button"
              ></div>
              <p>{videoSrc.name}</p>
            </div>
          )}

          {croppedImage && (
            <div className="cropped-image">
              <div
                className="clear-img-preview"
                onClick={handleImageReset}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    handleImageReset();
                  }
                }}
                tabIndex="0"
                aria-label={dictionary.Placeholders.DeletePicture}
                role="button"
              ></div>
              <img className="img-preview" src={croppedImage} />
            </div>
          )}
          {!croppedImage && !videoSrc && (
            <Fragment>
              <p>{dictionary.Labels.ChooseMedia}</p>
              <div
                className={`custom-file-upload ${
                  videoSrcError.length > 0 ? "input--error" : ""
                }`}
              >
                <label className="file-upload">
                  <span>Video</span>
                  <input
                    id="video-upload"
                    type="file"
                    aria-label={dictionary.Modal.Upload.ImportFileButton}
                    accept={videoAccept}
                    onChange={handleVideoChange}
                    tabIndex={isModalOpen ? "-1" : "0"}
                  />
                </label>
              </div>
              {videoSrcError.length > 0 && (
                <p className="input-error-message">{videoSrcError}</p>
              )}
              <button
                type="button"
                className="custom-file-upload custom-file-upload--image"
                onClick={toggleModal}
                tabIndex={isModalOpen ? "-1" : "0"}
              >
                <div className="file-upload">
                  <span>Billede</span>
                  <div id="file-upload" />
                </div>
              </button>
            </Fragment>
          )}
        </div>
        {isModalOpen && (
          <div className="file-modal">
            <ModalImageUpload
              handleImageChange={handleImageChange}
              closeModal={toggleModal}
              imgSrc={imgSrc}
              setCroppedImage={setCroppedImage}
              setIsModalOpen={setIsModalOpen}
              setImgSrc={setImgSrc}
              categories={categories}
              watch={watch}
              setFormValue={setFormValue}
              dictionary={dictionary}
              imageAccept={imageAccept}
              imageSrcError={imageSrcError}
              errors={errors}
            />
          </div>
        )}
      </div>
      {isModalOpen && <div className="file-modal__overlay"></div>}
    </div>
  );
};

export default CreatePostContent;
