import React from "react";

const ButtonPrevious = ({ onStepChange, dictionary }) => (
  <div className="col-sm-6">
    <button
      type="button"
      aria-label={dictionary.Labels.ButtonPrevious}
      onClick={onStepChange}
      className="button button--nostyling button--back"
    >
      <span>{dictionary.Labels.ButtonPrevious}</span>
    </button>
  </div>
);

export default ButtonPrevious;
