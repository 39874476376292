import React, { useEffect } from "react";
import { useUpdateEffect } from "../../hooks/useUpdateEffect";

const InputPhone = (props) => {
  const {
    errors,
    register,
    dictionary,
    name,
    watch,
    getValues,
    setValue,
    contactTypes,
    currentContactType,
    triggerValidation,
    prevContactType
  } = props;

  const watchCountryCode = watch("countryCode");
  const watchPhone = watch("phone");
  const resetInternationalCountryCodeNumber = prevContactType === contactTypes.DANISH_CONTACT_TYPE || 
  prevContactType === contactTypes.EMAIL_CONTACT_TYPE;

  const danish = {
    countryCodeNumber: "45",
    isCountryCodeDisabled: "disabled",
    phonePattern: /^(?=2|3[0-6]|3[8-9]|4[0-9]|5[0-9]|6[0-6]|69|7[1-9]|8[12]|8[6-9]|9[123]|9[6-9])\d{8}$/,
  };

  const international = {
    countryCodeNumber: resetInternationalCountryCodeNumber ? "" : watchCountryCode,
    isCountryCodeDisabled: "",
    phonePattern: /^\d+$/,
  };

  const isDanishNumber =
    currentContactType === contactTypes.DANISH_CONTACT_TYPE;
  const currentType = isDanishNumber ? danish : international;

  useEffect(() => {
    handleContactTypeSwitch();
  }, [currentContactType]);

  useUpdateEffect(() => {
    setCompletePhoneNumber();
  }, [watchCountryCode]);

  useUpdateEffect(() => {
    setCompletePhoneNumber();
  }, [watchPhone]);

  useUpdateEffect(() => {
    setCompletePhoneNumber();
  }, [errors.phone, errors.countryCode]);

  const handleContactTypeSwitch = () => {
    setValue("countryCode", currentType.countryCodeNumber);
    if (isDanishNumber) {
      triggerValidation("countryCode");
    }
    if (watchPhone && watchPhone.length > 1) {
      triggerValidation("phone");
    }
  };

  const setCompletePhoneNumber = () => {
    if (errors.countryCode || errors.phone) return;
    const completePhoneNumber = `+${watchCountryCode}${getValues("phone")}`;
    setValue(name, completePhoneNumber);
  };

  return (
    <div className="forum-input-col col-md-6">
      <div className="phone-input">
        <input
          className={`phone-input__country-code ${
            errors.countryCode ? "input--error" : ""
          }`}
          {...register("countryCode", {
            required: {
              value: true,
              message: dictionary.Errors.Required.CountryCode,
            },
          })}
          type="text"
          placeholder={dictionary.Placeholders.CountryCode}
          disabled={currentType.isCountryCodeDisabled}
          autocomplete="tel-country-code"
        />
        <span>+</span>
        <input
          className={`phone-input__number ${
            errors.phone ? "input--error" : ""
          }`}
          {...register("phone", {
            required: {
              value: true,
              message: dictionary.Errors.Required.Phone,
            },
            pattern: {
              value: currentType.phonePattern,
              message: dictionary.Errors.Phone,
            },
          })}
          type="tel"
          aria-label={dictionary.Placeholders.ContactPhone}
          placeholder={dictionary.Placeholders.ContactPhone}
          autocomplete="tel-local"
        />
      </div>
      {errors.countryCode && (
        <div className="input-error-message">{errors.countryCode.message}</div>
      )}
      {errors.phone && (
        <div className="input-error-message">{errors.phone.message}</div>
      )}
    </div>
  );
};

export default InputPhone;
