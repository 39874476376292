import React from "react"
import ReactDom from "react-dom"

class DynamicSearchFacets extends React.Component {

    constructor() {
        super();

        this.initData()
        this.handleChange = this.handleChange.bind(this);
        this.handleShowMore = this.handleShowMore.bind(this);
        this.handleShowLess = this.handleShowLess.bind(this);

    }

    initData() {

        let urlParams = new URLSearchParams(window.location.search);


        let facets = window.facetData.map(facetGroup => {
            let facetField = urlParams.get(facetGroup.FacetField);
            var showAllLocked = (facetField != undefined && facetField.length > 0) || facetGroup.SearchFacetValues.length < 6 ? true : false;
            facetGroup.showAllLocked = showAllLocked;
            facetGroup.showAll = showAllLocked;
            facetGroup.facetCollection = () => facetGroup.showAll ? facetGroup.SearchFacetValues : facetGroup.SearchFacetValues.slice(0, 5);
            return facetGroup;
        });

        this.state = {
            data: facets
        }

    }

    buildUrl(facetField, facetValue) {
        facetValue = encodeURI(facetValue);
        let result = [];
        window.location.search.split("&").forEach( element => {
            let keyValue = element.split("=");
            result.push({'key': keyValue[0], 'value':  keyValue[1]}) 
        });

        let currentUrlFacet = result.find(p => p.key===facetField);
        
        if (currentUrlFacet == undefined){
            currentUrlFacet = "";
        }else{
            currentUrlFacet = currentUrlFacet.value;
        }
            
        let rawCurrentUrlFacetValues = currentUrlFacet.split(',');
        let currentUrlFacetValues = rawCurrentUrlFacetValues.filter(Boolean);
        var facetIndex = currentUrlFacetValues.indexOf(facetValue);
        if (facetIndex > -1) {
            currentUrlFacetValues.splice(facetIndex, 1);

        } else {
            currentUrlFacetValues.push(facetValue)
        }
        let newFieldValue = currentUrlFacetValues.toString();
        let facetFound = false;
        var testArray = result.reduce((p, c) => {
            let fieldValue;
            if(c.key === facetField){
                fieldValue = newFieldValue;
                facetFound = true;
            }else{
                fieldValue = c.value;
            }
            p.push(c.key+"="+fieldValue);
            return p;
        }, [])

        if(!facetFound)
            testArray.push(facetField+"="+facetValue);
        let updatedUrlParamaters = testArray.join("&");
        
        let redirectUrl = window.location.protocol + "//" + window.location.hostname + window.location.pathname + updatedUrlParamaters;
        return redirectUrl;

    }

    isFacetValuePresentInUrl(facetField, facetValue) {
        let urlParams = new URLSearchParams(window.location.search);
        var urlParam = urlParams.get(facetField);
        if (urlParam == undefined)
            return false;

        let selectedFacetValues = urlParam.split(',');
        return selectedFacetValues.includes(facetValue);
    }

    getAccordionState(facetGrp) {
        if (!facetGrp.IsAccordion)
            return "is-disabled";

        let urlParams = new URLSearchParams(window.location.search);
        if (!sortableSearchFacet.IsAccordionOpen && urlParams.get(facetGrp.FacetField) == undefined)
            return "collapsed";

        return string.Empty;
    }

    handleChange(facetField, facetValue) {
        let url = this.buildUrl(facetField, facetValue);
        window.location.href = url;
    }

    handleShowMore(facetGrp) {
        facetGrp.showAll = true;
        this.setState({state: this.state});
    }

    handleShowLess(facetGrp) {
        facetGrp.showAll = false;
        this.setState({state: this.state});
    }


    render() {
        return (
            <div className="facet-menu__panels">
                {this.state.data.map((facetGrp, index) => (
                    <div key={index} className="panel-group facet-menu-container informationspot-d">
                        <div className="panel panel-middle">
                            <div className="panel-heading result-list-spot-accordion__heading">
                                <h2 className="panel-title result-list-spot-accordion__heading__title">
                                    <a data-toggle="collapse" data-parent="#accordion" className="is-disabled"
                                       href="accordion-">
                                        <span className="js-result-list-spot-accordion "
                                              data-search-facet-title="{facetGrp.FacetTitle}">{facetGrp.FacetTitle}</span>
                                    </a>
                                </h2>
                            </div>
                            <div id="accordion-{facet.DisplayName}"
                                 className="result-list-spot-accordion__content panel-collapse collapse in">
                                <div className="panel-body result-list-spot-accordion__table-wrapper">
                                    <ul className="list-facets">
                                        {/*Show top facets*/}
                                        {facetGrp.facetCollection().map((facet, index) => (
                                            <li key={index} 
                                                className={"list-facets__items " + (this.isFacetValuePresentInUrl(facetGrp.FacetField, facet.FacetValue) ? "is-checked" : "not-checked")}>
                                                <span className="list-facets__checkbox">
                                                    <span className="custom-checkbox">
                                                        <input id={facetGrp.FacetField + index} type="checkbox" onChange={() => this.handleChange(facetGrp.FacetField, facet.FacetValue)}
                                                               checked={this.isFacetValuePresentInUrl(facetGrp.FacetField, facet.FacetValue)}/>
                                                        <span className="icon"></span>
                                                    </span>
                                                    <label htmlFor={facetGrp.FacetField + index}>{facet.DisplayName} <span className="list-facets__label-count">({facet.NumberOfHits})</span></label>
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                    <div className="facet-menu__show-more"
                                         style={{display: !facetGrp.showAll && !facetGrp.showAllLocked ? "block" : "none"}}>
                                        <a className="btn-right icon-pil-spinkel-hoejre btn-searchoptions filters collapsed"
                                           onClick={() => this.handleShowMore(facetGrp)}>Vis mere</a>
                                        <span className="print-qr btn-right btn-searchoptions--print">Alle</span>
                                    </div>
                                    <div className="facet-menu__show-less"
                                         style={{display: facetGrp.showAll && !facetGrp.showAllLocked ? "block" : "none"}}>
                                        <a className="btn-right icon-pil-spinkel-hoejre btn-searchoptions filters collapsed"
                                           onClick={() => this.handleShowLess(facetGrp)}>Vis mindre</a>
                                        <span className="print-qr btn-right btn-searchoptions--print">Alle</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        )
    }
}


let dynamicSearchFacetsRoot = document.getElementById("DynamicSearchFacetsRoot")
if (dynamicSearchFacetsRoot) {
    ReactDom.render(<DynamicSearchFacets/>, dynamicSearchFacetsRoot)
}
export default DynamicSearchFacets