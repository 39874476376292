import React from "react";

const ButtonSubmit = ({ isValid, dictionary, loading }) => (
  <div className="col-sm-6">
    <button
      disabled={!isValid || loading}
      type="submit"
      aria-label={dictionary.Labels.ButtonSubmit}
      className={`button pull-right submit-btn ${
        loading ? "submit-btn--loading" : ""
      }`}
    >
      <span>{dictionary.Labels.ButtonSubmit}</span>
      {loading && (
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
    </button>
  </div>
);

export default ButtonSubmit;
